import React from "react";
import { frontendTools, backendTools, frameworkTools, queryTools } from "../services/CreateLinks";
import mugshot from "../images/MohammedALJarah.jpg"
export default function AboutMe(){

    function sendmail(){
        window.open('mailto:test@example.com');
    }
    // const mugshotLink = "https://cdn.discordapp.com/attachments/854037407271878721/1194825747462893618/MohammedALJarah.JPG?ex=65b1c330&is=659f4e30&hm=7c63b10d248d377a7fa0fde2a05f5b2a84217c7af144f2953b2c0fa927abadb6&"
    
  
        
    return(
        <section id="aboutme" className="flex flex-col items-center w-full mb-32 pt-16">
            <div className="w-screen px-4 py-4 flex flex-col items-center lg:container">

                <div className="relative flex justify-center mb-12">
                    <h1 className="text-center text-accent-def text-5xl font-bold py-4 px-10 rounded-tl-lg rounded-tr-lg">About me</h1>
                    <div className="absolute bg-white h-0.5 w-1/3 bottom-0"></div>
                </div>

                <div className="text-text-def flex flex-col md:flex-row">
                    {/* LEFT Side */}
                    <div className="md:w-1/2 flex flex-col items-center">   
                        <img className="rounded-full h-[192px] w-[192px] mb-4 text-center border" src={mugshot} alt="my face"/>
                            <p className="text-xl text-center">I'm a passionate software engineering graduate,
                                currently working as a frontend developer @ Smart Wave.
                                <br/>
                                I hold a bachelor's degree in Software Engineering from Istanbul Aydin University,
                                my academic journey has equipped me with a solid foundation in databases, data structures, and software engineering principles.
                                {/* <br/>
                                Driven by a strong desire to solve real-world problems.
                                I spend my time learning new tech and tools or solve problems on leetcode, currently learning Machine Learning and Unity. */}
                            </p>
                    </div>



                    {/* RIGHT Side */}
                    <div className="flex flex-col items-center md:w-1/2 mt-12 md:mt-0">
                        <p className="text-4xl">Skills</p>
                        <p className="text-2xl text-center mt-2">Frontend</p>
                        <ul className="text-text-def text-center mb-4 text-xl flex gap-4 mt-2 justify-center flex-wrap">
                            {frontendTools.map((image, index)=>(
                                <li key={index}>
                                    <img src={image.url} alt={image.alt} className="w-[48px]" />
                                </li>
                            ))}
                        </ul>

                        <p className="text-2xl text-center">Backend</p>
                        <ul className="text-text-def text-center mb-4 text-xl flex gap-4 mt-2 justify-center flex-wrap">
                            {backendTools.map((image, index)=>(
                                <li key={index}>
                                    <li><img src={image.url} alt={image.alt} className="w-[48px]" /></li>
                                </li>
                            ))}

                        </ul>

                        <p className="text-2xl text-center">Framework & Libraries</p>
                        <ul className="text-text-def text-center mb-4 text-xl flex gap-4 mt-2 justify-center flex-wrap">
                            {frameworkTools.map((image, index)=>(
                                <li key={index}>
                                    <li><img src={image.url} alt={image.alt} className="w-[48px]" /></li>
                                </li>
                            ))}

                        </ul>


                        <p className="text-2xl text-center">Query Languages</p>
                        <ul className="text-text-def text-center mb-4 text-xl flex gap-4 mt-2">
                            {queryTools.map((image, index)=>(
                                <li key={index}>
                                    <li><img src={image.url} alt={image.alt} className="w-[48px]" /></li>
                                </li>
                            ))}    
                        </ul>

                    </div>

                </div>

            </div>
        </section>
    );
}