import React from "react";
import github from "../images/github.png"
import linkedin from "../images/linkedin2.png"
import instagram from "../images/instagram.png"
function Footer(){
    return(
        <div className="md:h-64 md:container md:mx-auto flex flex-col md:flex-row items-center md:justify-between h-40 justify-around w-full">
            <div>
                <p className="text-text-def">&copy;{new Date().getFullYear()} - Mohammad AL-Jarah</p>
            </div>
            {/* <ul className="md:flex gap-4 text-primary-500 hidden">
                <li className="hover:text-secondary-def">
                    <a target="_blank" rel="noreferrer" href="https://github.com/mohammadaljarah">
                        Github
                    </a>
                </li>
                <li className="hover:text-secondary-def">
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/mohammed-aljarah/">
                        LinkedIn
                    </a>
                </li>
                <li className="hover:text-secondary-def">
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/the.eminjay">
                        Instagram
                    </a>
                </li>
            </ul> */}
            <ul className="flex gap-4 ">
                <li>
                    <a target="_blank" href="https://github.com/mohammadaljarah" rel="noreferrer">
                        <img src={github} className="invert h-8" alt="github"/>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.linkedin.com/in/mohammed-aljarah/" rel="noreferrer">
                        <img src={linkedin} className="invert h-8" alt="linkedin"/>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.instagram.com/the.eminjay" rel="noreferrer">
                        <img src={instagram} className="invert h-8" alt="instagram"/>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Footer;