import React, { useEffect } from "react";
//import logo from "../images/Logo.png"
// import github from "../images/github.png"
// import linkedin from "../images/linkedin2.png"
// import instagram from "../images/instagram.png"
import { Link } from 'react-router-dom';
import Owl from "./Owl";
import { useLocation } from 'react-router-dom';
function Navbar(){
    var location = useLocation();

    useEffect(() => {
        // Code to handle scroll logic
        var navbar = document.getElementById('mynav');
        var firstdiv = document.getElementById('firstdiv');
    
        // Add an event listener to the window for the 'scroll' event
        const handleScroll = () => {
          // Check if the user has scrolled beyond a certain threshold
          var scrollPosition = window.scrollY || window.pageYOffset;
          var threshold = 100; // Adjust this value based on your needs
    
          if (scrollPosition > threshold) {
            // Add Tailwind classes when scrolled beyond the threshold
            if(navbar){
                navbar.classList.add('fixed', 'top-0', 'bg-background-def', 'left-1/2', '-translate-x-1/2');
                navbar.classList.remove('-top-16');
            }
            if(firstdiv){
                firstdiv.classList.add('mt-16');
            }
          } else {
            // Remove Tailwind classes when scrolled back above the threshold
            if(navbar){
                navbar.classList.remove('fixed', 'top-0', 'bg-background-def', 'left-1/2', '-translate-x-1/2');
                navbar.classList.add('-top-16');
            }
            if(firstdiv){
                firstdiv.classList.remove('mt-16');
            }
          }
        }; 
    
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    

    return(
        <nav id="mynav" className="h-16 mb-4 w-full drop-shadow-md flex items-center justify-between lg:container lg:mx-auto px-1 xs:px-4 z-50 -top-16">
            <a href="/">
                {/* <img src={logo} className="invert h-10"/> */}
                <Owl dimensions={["2.5rem", "2.5rem"]} animate={false}/>
            </a>

            <ul className="flex gap-4 sm:gap-10 ">
                {/* <li className={`text-accent-def hover:text-accent-300  text-lg ${location.pathname==="/" ? "text-secondary-500 hover:text-secondary-600" : ""}`}>
                    <Link to="/">
                        Home
                    </Link>
                </li>
                <li className={`text-accent-def hover:text-accent-300  text-lg ${location.pathname==="/About" ? "text-secondary-500 hover:text-secondary-600" : ""}`}>
                    <Link to="/About">
                        About
                    </Link>
                </li> */}
                {/* <li className="text-accent-def hover:text-accent-300 text-lg">
                    <Link to="/portfolio/Experience">
                        Experience
                    </Link>
                </li> */}
                <li>
                    <a href="#aboutme" className="text-accent-def hover:text-accent-300  text-lg">About</a>
                </li>
                <li>
                    <a href="#projects" className="text-accent-def hover:text-accent-300  text-lg">Projects</a>
                </li>
                <li>
                    <a href="#experience" className="text-accent-def hover:text-accent-300  text-lg">Experience</a>
                </li>
            </ul>
        </nav>
    );
}
export default Navbar;
