import React from "react";
function Resume(){

    return(
        <div id="about" className="p-4 md:pl-16 sm:px-4 sm:pt-32 lg:container lg:mx-auto">

            {/* ABOUT ME */}
            <h1 className="text-accent-def text-6xl mb-4">About Me</h1>
            <p className="text-text-def text-xl mb-14">Hey, <span className="text-primary-400">Mohammad</span> here again, 
                I'm a passionate 
                <span className="text-secondary-def"> software engineering graduate</span>,
                <br/>
                currently working as <span className="text-primary-400">frontend developer </span> @ Smart Wave.
                <br/>
                Driven by a strong desire to solve real-world problems.
                <br/> 
                I spend my time learning new tech and tools or solve problems on leetcode, currently learning Unity.
                </p>


            {/* EDUCATION */}
            <h2 className="text-accent-def text-5xl my-4">Education</h2>
            <p className="text-text-def text-xl mb-14">
                I hold a bachelor's degree in
                <span className="text-secondary-def"> Software Engineering</span> from <span className="text-secondary-def">Istanbul Aydin University</span>,
                <br/>
                my academic journey has equipped me with a solid foundation in databases,
                data structures, and software engineering principles.
            </p>



            {/* SKILLS */}
            <h2 className="text-accent-def text-5xl my-4">Skills</h2>
            <div className="grid grid-cols-2 grid-rows-2 mb-14">

                <div className="row-start-1">
                    <h3 className="text-accent-def text-3xl">Frontend</h3>
                    <ul className="text-text-def list-disc ml-6 text-xl">
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>TypeScript</li>
                        <li>JavaScript</li>
                    </ul>
                </div>

                <div className="row-start-2 mt-4">
                    <h3 className="text-accent-def text-3xl">Backend</h3>
                    <ul className="text-text-def list-disc ml-6 text-xl">
                        <li>C#</li>
                        <li>TypeScript</li>
                        <li>JavaScript</li>
                        <li>REST API</li>
                        <li>Python</li>
                        <li>Java</li>
                    </ul>
                </div>

                <div className="col-start-2 row-start-1">
                    <h3 className="text-accent-def text-3xl">Framework & Libraries</h3>
                    <ul className="text-text-def list-disc ml-6 text-xl">
                        <li>ASP.NET</li>
                        <li>React.js</li>
                        <li>Tailwind</li>
                        <li>Bootstrap</li>
                        <li>Blazor</li>
                        <li>MAUI</li>
                    </ul>
                </div>

                <div className="col-start-2 row-start-2 mt-4">
                    <h3 className="text-accent-def text-3xl">Query Languages</h3>
                    <ul className="text-text-def list-disc ml-6 text-xl">
                        <li>SQL</li>    
                    </ul>
                </div>
            </div>

            <h2 className="text-accent-def text-5xl my-4">Personal Touch</h2>
            <p className="text-text-def text-xl mb-14">
                Beyond my professional life and programming, I play video games and listen to music. 
                <br/>
                I believe in the power of collaboration and continuous learning, which I incorporate into both my personal and professional pursuits.
            </p>
            
        </div>
        // <div className="p-4 md:pl-16 sm:px-4 sm:pt-32 lg:container lg:mx-auto">

        //     {/* ABOUT ME */}
        //     <h1 className="text-accent-def text-6xl mb-4">About Me</h1>
        //     <p className="text-text-def text-xl mb-14">Hey, <span className="text-primary-400">Mohammad</span> here again, 
        //         I<span className="text-secondary-500">'</span>m a passionate 
        //         <span className="text-secondary-def"> software engineering </span> 
        //         graduate<span className="text-secondary-def">,</span> 
        //         <br/>
        //         currently working as <span className="text-primary-400">frontend developer </span>
        //         <span className="text-secondary-500">@</span> Smart Wave<span className="text-secondary-500">.</span>
        //         <br/>
        //         Driven by a strong desire to solve real<span className="text-secondary-500">-</span>world problems<span className="text-secondary-def">.</span>
        //         <br/> 
        //         I spend my time learning new tech and tools or solve problems on leetcode<span className="text-secondary-def">, </span>
        //         currently learning Unity<span className="text-secondary-def">.</span>
        //     </p>


        //     {/* EDUCATION */}
        //     <h2 className="text-accent-def text-5xl my-4">Education</h2>
        //     <p className="text-text-def text-xl mb-14">
        //         I hold a bachelor<span className="text-secondary-def">'</span>s degree in
        //         <span className="text-secondary-def"> Software Engineering</span> from <span className="text-secondary-def">Istanbul Aydin University,</span>
        //         <br/>
        //         my academic journey has equipped me with a solid foundation in databases<span className="text-secondary-500">, </span>
        //         data structures<span className="text-secondary-500">,</span> and software engineering principles
        //         <span className="text-secondary-def">.</span>
        //     </p>



        //     {/* SKILLS */}
        //     <h2 className="text-accent-def text-5xl my-4">Skills</h2>
        //     <div className="grid grid-cols-2 grid-rows-2 mb-14">

        //         <div className="row-start-1">
        //             <h3 className="text-accent-def text-3xl">Frontend</h3>
        //             <ul className="text-text-def list-disc ml-6 text-xl">
        //                 <li>HTML</li>
        //                 <li>CSS</li>
        //                 <li>TypeScript</li>
        //                 <li>JavaScript</li>
        //             </ul>
        //         </div>

        //         <div className="row-start-2 mt-4">
        //             <h3 className="text-accent-def text-3xl">Backend</h3>
        //             <ul className="text-text-def list-disc ml-6 text-xl">
        //                 <li>C#</li>
        //                 <li>TypeScript</li>
        //                 <li>JavaScript</li>
        //                 <li>REST API</li>
        //                 <li>Python</li>
        //                 <li>Java</li>
        //             </ul>
        //         </div>

        //         <div className="col-start-2 row-start-1">
        //             <h3 className="text-accent-def text-3xl">Framework & Libraries</h3>
        //             <ul className="text-text-def list-disc ml-6 text-xl">
        //                 <li>ASP<span className="text-secondary-def">.</span>NET</li>
        //                 <li>React<span className="text-secondary-def">.</span>js</li>
        //                 <li>Tailwind</li>
        //                 <li>Bootstrap</li>
        //                 <li>Blazor</li>
        //                 <li>MAUI</li>
        //             </ul>
        //         </div>

        //         <div className="col-start-2 row-start-2 mt-4">
        //             <h3 className="text-accent-def text-3xl">Query Languages</h3>
        //             <ul className="text-text-def list-disc ml-6 text-xl">
        //                 <li>SQL</li>    
        //             </ul>
        //         </div>
        //     </div>

        //     <h2 className="text-accent-def text-5xl my-4">Personal Touch</h2>
        //     <p className="text-text-def text-xl mb-14">
        //         Beyond my professional life and programming<span className="text-secondary-def">, </span> 
        //         I play video games and listen to music<span className="text-secondary-def">.</span> 
        //         <br/>
        //         I believe in the power of collaboration and continuous learning<span className="text-secondary-def">,</span> which I incorporate into both my personal and professional pursuits
        //         <span className="text-secondary-def">.</span>
        //     </p>
            
        // </div>
    );

}


export default Resume;