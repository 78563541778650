import React from 'react';
type Props={
    dimensions:[string, string];
    animate: boolean;
}
function Owl(props: Props){
    return(

        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" height={props.dimensions[0]} width={props.dimensions[1]}  viewBox="0 0 512 512">
            {
                props.animate &&
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="translate"
                        values="0 0; 0 10; 0 0; 0 -10; 0 0"
                        dur="2s"
                        repeatCount="indefinite"
                    />
            }
            <path d="M359.225 123.428c-19.522 0-35.404 15.883-35.404 35.404s15.882 35.404 35.404 35.404c19.522 0 35.404-15.883 35.404-35.404s-15.882-35.404-35.404-35.404zm0 54.468c-10.511 0-19.064-8.553-19.064-19.064s8.551-19.064 19.064-19.064c10.511 0 19.064 8.553 19.064 19.064s-8.553 19.064-19.064 19.064zm-158.518-54.468c-19.521 0-35.404 15.883-35.404 35.404s15.883 35.404 35.404 35.404 35.404-15.883 35.404-35.404-15.882-35.404-35.404-35.404zm0 54.468c-10.511 0-19.064-8.553-19.064-19.064s8.553-19.064 19.064-19.064 19.064 8.553 19.064 19.064-8.552 19.064-19.064 19.064z"/>
            <path d="M419.404 0c-21.47 0-40.456 13.155-48.182 32.681H188.71C180.983 13.155 161.998 0 140.528 0c-4.512 0-8.17 3.658-8.17 8.17v181.366c0 4.512 3.658 8.17 8.17 8.17 4.512 0 8.17-3.658 8.17-8.17V81.957c54.16 3.064 102.161 36.39 123.76 86.69 1.29 3.001 4.242 4.947 7.508 4.947s6.218-1.946 7.508-4.947c21.599-50.3 69.6-83.626 123.76-86.69v142.996c0 146.536-117.033 266.25-262.536 270.584v-25.28c70.805-23.559 122.008-90.433 122.008-169.05 0-51.358-41.782-93.14-93.14-93.14s-93.14 41.782-93.14 93.14v169.94c0 4.512 3.658 8.17 8.17 8.17 13.664 0 26.973-1.551 39.762-4.478v28.99c0 4.512 3.658 8.17 8.17 8.17 158.278 0 287.047-128.769 287.047-287.047V8.17c-.001-4.512-3.658-8.17-8.171-8.17zm-8.17 65.592c-55.054 2.795-104.502 33.659-131.268 81.389-26.766-47.73-76.215-78.594-131.268-81.389V17.287c12.605 2.969 22.848 12.674 26.185 25.604.931 3.608 4.185 6.129 7.911 6.129h194.344c3.726 0 6.98-2.521 7.911-6.129 3.337-12.931 13.58-22.636 26.185-25.604v48.305zM140.528 454.947c-2.295 0-4.366.95-5.851 2.474-10.897 2.938-22.242 4.773-33.911 5.355V301.208c0-42.348 34.452-76.8 76.8-76.8s76.8 34.452 76.8 76.8c0 71.857-47.093 132.914-112.035 153.944-.581-.13-1.182-.205-1.803-.205z"/>
            <path d="M157.957 293.038c-4.512 0-8.17 3.658-8.17 8.17 0 4.506-3.665 8.17-8.17 8.17-4.506 0-8.17-3.665-8.17-8.17 0-4.512-3.658-8.17-8.17-8.17-4.512 0-8.17 3.658-8.17 8.17 0 13.516 10.995 24.511 24.511 24.511s24.511-10.995 24.511-24.511c-.001-4.512-3.659-8.17-8.172-8.17zm16.341 49.022c-4.512 0-8.17 3.658-8.17 8.17 0 4.506-3.665 8.17-8.17 8.17s-8.17-3.665-8.17-8.17c0-4.512-3.658-8.17-8.17-8.17-4.512 0-8.17 3.658-8.17 8.17 0 13.516 10.995 24.511 24.511 24.511s24.511-10.995 24.511-24.511c-.002-4.512-3.66-8.17-8.172-8.17z"/>
        </svg>


        // <svg fill="#FFFFFF" height={props.dimensions[0]} width={props.dimensions[1]} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
        // // viewBox="0 0 512 512" >
	    //     viewBox="0 0 512 512" transform="scale(1,1) scale(-1,1)"> 
        //     {/* {
        //         props.animate &&
        //             <animateTransform
        //                 attributeName="transform"
        //                 attributeType="XML"
        //                 type="translate"
        //                 values="-1 0; -1 10; -1 0; -1 -10; -1 0"
        //                 dur="2s"
        //                 repeatCount="indefinite"
        //             />
        //     } */}
            
        //         <path d="M359.225,123.428c-19.522,0-35.404,15.883-35.404,35.404s15.882,35.404,35.404,35.404
        //             c19.522,0,35.404-15.883,35.404-35.404S378.747,123.428,359.225,123.428z M359.225,177.896c-10.511,0-19.064-8.553-19.064-19.064
        //             s8.551-19.064,19.064-19.064c10.511,0,19.064,8.553,19.064,19.064S369.736,177.896,359.225,177.896z"/>
            
        
        
            
        //         <path d="M200.707,123.428c-19.521,0-35.404,15.883-35.404,35.404s15.883,35.404,35.404,35.404s35.404-15.883,35.404-35.404
        //             S220.229,123.428,200.707,123.428z M200.707,177.896c-10.511,0-19.064-8.553-19.064-19.064s8.553-19.064,19.064-19.064
        //             s19.064,8.553,19.064,19.064S211.219,177.896,200.707,177.896z"/>
            
        
        
            
        //         <path d="M419.404,0c-21.47,0-40.456,13.155-48.182,32.681H188.71C180.983,13.155,161.998,0,140.528,0
        //             c-4.512,0-8.17,3.658-8.17,8.17v181.366c0,4.512,3.658,8.17,8.17,8.17c4.512,0,8.17-3.658,8.17-8.17V81.957
        //             c54.16,3.064,102.161,36.39,123.76,86.69c1.29,3.001,4.242,4.947,7.508,4.947c3.266,0,6.218-1.946,7.508-4.947
        //             c21.599-50.3,69.6-83.626,123.76-86.69v142.996c0,146.536-117.033,266.25-262.536,270.584v-25.28
        //             c70.805-23.559,122.008-90.433,122.008-169.05c0-51.358-41.782-93.14-93.14-93.14s-93.14,41.782-93.14,93.14v169.94
        //             c0,4.512,3.658,8.17,8.17,8.17c13.664,0,26.973-1.551,39.762-4.478v28.99c0,4.512,3.658,8.17,8.17,8.17
        //             c158.278,0,287.047-128.769,287.047-287.047V8.17C427.574,3.658,423.917,0,419.404,0z M411.234,65.592
        //             c-55.054,2.795-104.502,33.659-131.268,81.389c-26.766-47.73-76.215-78.594-131.268-81.389V17.287
        //             c12.605,2.969,22.848,12.674,26.185,25.604c0.931,3.608,4.185,6.129,7.911,6.129h194.344c3.726,0,6.98-2.521,7.911-6.129
        //             c3.337-12.931,13.58-22.636,26.185-25.604V65.592z M140.528,454.947c-2.295,0-4.366,0.95-5.851,2.474
        //             c-10.897,2.938-22.242,4.773-33.911,5.355V301.208c0-42.348,34.452-76.8,76.8-76.8s76.8,34.452,76.8,76.8
        //             c0,71.857-47.093,132.914-112.035,153.944C141.75,455.022,141.149,454.947,140.528,454.947z"/>
            
        
            
        //         <path d="M157.957,293.038c-4.512,0-8.17,3.658-8.17,8.17c0,4.506-3.665,8.17-8.17,8.17c-4.506,0-8.17-3.665-8.17-8.17
        //             c0-4.512-3.658-8.17-8.17-8.17c-4.512,0-8.17,3.658-8.17,8.17c0,13.516,10.995,24.511,24.511,24.511
        //             c13.516,0,24.511-10.995,24.511-24.511C166.128,296.696,162.47,293.038,157.957,293.038z"/>
            
        //         <path d="M174.298,342.06c-4.512,0-8.17,3.658-8.17,8.17c0,4.506-3.665,8.17-8.17,8.17s-8.17-3.665-8.17-8.17
        //             c0-4.512-3.658-8.17-8.17-8.17c-4.512,0-8.17,3.658-8.17,8.17c0,13.516,10.995,24.511,24.511,24.511s24.511-10.995,24.511-24.511
        //             C182.468,345.718,178.81,342.06,174.298,342.06z"/>
            
            
        // </svg>  
    )
}

export default Owl;

