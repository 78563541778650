import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/Homepage';
import Navbar from './components/Navbar';
import Resume from './components/Resume';
import Footer from './components/Footer';

function App() {
  return (
    // <div className='bg-background-def h-screen'>
      <Router>
        <Navbar/>
          <Routes>
              {/* <Route path='/portfolio/' element = {<Homepage/>} />
              <Route path='/portfolio/About' element = {<About/>} /> */}
              <Route path='/' element = {<Homepage/>} />
              <Route path='/About' element = {<Resume/>} />
          </Routes>
          <Footer/>
      </Router>
    // {/* </div> */}
    
  );
}

export default App;
