import React, { useEffect, useState } from "react";
import Owl from "./Owl";
import ExperienceCard from "./ExperienceCard";
import Experience from "../models/Experience";
import { experiencesFixed } from "../services/CreateExperiences";
import Projects from "./Projects";
import AboutMe from "./AboutMe";
import Experiences from "./Experiences";

// import { GetExps } from "../Firebase";
function Homepage(){
    
    

    return(
        <div id="firstdiv" className=" sm:pl-4 sm:pr-4 sm:pt-32 lg:container lg:mx-auto">

            <section id="header" className="relative pb-64 flex justify-between pr-4 pl-4 sm:pr-0 sm:pl-0">
                <div className="mt-8">
                    <p className="text-text-def text-xl ml-[6px] text-center md:text-start">
                        Hello World!, my name is
                    </p>
                    <p className="text-primary-500 text-4xl xs:text-6xl sm:text-8xl text-center md:text-start">
                        Mohammad <span className="inline-block">AL-Jarah</span> <span className="inline-block sm:hidden"></span>
                    </p>
                    <p className="text-text-def text-3xl xs:text-5xl sm:text-6xl text-center md:text-start">
                        Jr. Software Engineer<span className="text-secondary-def">.</span>
                    </p>
                </div>
                <div className="hidden md:inline">
                    <Owl dimensions={["300px", "300px"]} animate={true}/>
                </div>
                {/* <svg className="absolute top-64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0f0f10" fill-opacity="1" d="M0,192L60,213.3C120,235,240,277,360,272C480,267,600,213,720,181.3C840,149,960,139,1080,128C1200,117,1320,107,1380,101.3L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg> */}
            </section>
            
            <AboutMe/>

            <Projects/>

            <Experiences/>
            
        </div>
    );

}


export default Homepage;
