type images = {
    url: string;
    alt: string;
}

export const html: images = {alt:"HTML", url:"https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/html5/html5-plain.svg"}
export const css: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/css3/css3-plain.svg", alt: "CSS" };
export const js: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/javascript/javascript-plain.svg", alt: "JavaScript" };
export const ts: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/typescript/typescript-plain.svg", alt: "TypeScript" };

export const csharp: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/csharp/csharp-plain.svg", alt: "csharp" };
export const python: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/python/python-original.svg", alt: "Python" };
export const java: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/java/java-plain.svg", alt: "Java" };
// export const restApi: images = { url: "https://toppng.com/uploads/preview/rest-api-icon-rest-api-icon-11553510526uqs2ynyga2.png", alt: "REST API" };

export const dotnetCore: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/dotnetcore/dotnetcore-original.svg", alt: "dotnet core" };
export const react: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/react/react-original.svg", alt: "React.js" };
export const tailwind: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/tailwindcss/tailwindcss-plain.svg", alt: "Tailwind" };
export const bootstrap: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/bootstrap/bootstrap-original.svg", alt: "Bootstrap" };
export const blazor: images = { url: "https://seeklogo.com/images/B/blazor-logo-28A9304DC6-seeklogo.com.png", alt: "Blazor" };
export const maui: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/xamarin/xamarin-original.svg", alt: "MAUI" };

export const sqlite: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/sqlite/sqlite-original.svg", alt: "SQLite" };
export const mysql: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/mysql/mysql-original.svg", alt: "MySQL" };

export const stripe: images = {alt:"Stripe", url:"https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png"};
export const xaml: images = { url: "https://cdn3.iconfinder.com/data/icons/lexter-flat-colorfull-file-formats/56/xaml-512.png", alt: "XAML" };
export const winForm: images = { url: "https://www.i-programmer.info/images/stories/Core/Prof/net2.png", alt: "winform" };
export const javaFx: images = { url: "https://upload.wikimedia.org/wikipedia/en/c/cc/JavaFX_Logo.png", alt: "javafx" };
export const firebase: images = { url: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/firebase/firebase-plain.svg", alt: "firebase" };

export const frontendTools: images[] = [html, css, js, ts];
export const backendTools: images[] = [csharp, ts, js, python, java];
// export const backendTools: images[] = [csharp, ts, js, restApi, python, java];
export const frameworkTools: images[] = [dotnetCore, react, tailwind, bootstrap, blazor, maui];
export const queryTools: images[] = [mysql, sqlite];

